import React, { Component } from 'react';
import config from '../configuration.json';

export default class Introduction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devIntro: config.devIntro,
      bgPic1: config.bgPic1,
      bgPic2: config.bgPic2,
      bgPic3: config.bgPic3
    };
  }

  render() {
    return (
      <div>
        <section id="colorlib-hero" className="js-fullheight" data-section="home">
          <div className="flexslider js-fullheight">
            <ul className="slides">
              <li style={{backgroundImage: `url(${this.state.bgPic1})`}}>
                <div className="overlay" />
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                      <div className="slider-text-inner js-fullheight">
                        <div className="desc">
                          <h1>Hi! <br />{this.state.devIntro}</h1>
                          <p><a className="btn btn-primary btn-learn" href="#about" data-nav-section="about">Learn More</a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li style={{backgroundImage: `url(${this.state.bgPic2})`}}>
                <div className="overlay" />
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                      <div className="slider-text-inner">
                        <div className="desc">
                          <h1>He loves Developing<br /> THINGS!!</h1>
                          <p><a className="btn btn-primary btn-learn" href="#projects" data-nav-section="about">View Projects</a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </div>
    )
  }
}
