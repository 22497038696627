import React, { Component } from 'react';
import config from '../configuration.json';

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devName: config.devName,
      devEmail: config.devEmail,
      links: config.links,
      instaProfilePic: "https://scontent-lga3-1.cdninstagram.com/v/t51.2885-19/s320x320/89288815_212377483247040_4912832361965551616_n.jpg?_nc_ht=scontent-lga3-1.cdninstagram.com&_nc_ohc=Z0my2oLJWt4AX9ArXZG&tp=1&oh=3418c7f12ca6ab2a20ce936350f34b46&oe=5FF102BB",
      location: config.location
    };
  }

  render() {
    return (
      <div>
        <div>
          <nav href="#navbar" className="js-colorlib-nav-toggle colorlib-nav-toggle" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar"><i /></nav>
          <aside id="colorlib-aside" className="border js-fullheight">
            <div className="text-center">
              <div className="author-img" style={{ backgroundImage: `url(${this.state.instaProfilePic})`}} />
              <h1 id="colorlib-logo"><a href="index.html">{this.state.devName}</a></h1>
            </div>
            <nav id="colorlib-main-menu" role="navigation" className="navbar">
              <div id="navbar" className="collapse">
                <ul>
                  <li className="active"><a href="#home" data-nav-section="home">Home</a></li>
                  <li><a href="#about" data-nav-section="about">About</a></li>
                  <li><a href="#timeline" data-nav-section="timeline">Experience</a></li>
                  <li><a href="#education" data-nav-section="education">Education</a></li>
                  <li><a href="#projects" data-nav-section="projects">Projects</a></li>
                  <li><a href="#contact" data-nav-section="contact">Contact</a></li>
                </ul>
              </div>
            </nav>
            <nav id="colorlib-main-menu">
              <ul>
                {this.state.links.map(icon => {
                  return <li key={icon.id}><a href={`${icon.url}`} target="_blank" rel="noopener noreferrer"><i className={`${icon.image}`} /></a></li>
                })}
              </ul>
            </nav>
            <div className="colorlib-footer">
              <p><small>
                  Made with <i className="icon-heart" aria-hidden="true" /> and <i className="icon-code" aria-hidden="true"></i><br></br>
                  Thanks <a href="https://colorlib.com" target="_blank" rel="noopener noreferrer">Colorlib</a> for inspiration 
              </small></p>
            </div>
          </aside>
        </div>
      </div>
    )
  }
}
