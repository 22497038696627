import React, { Component } from 'react';
import config from '../configuration.json';
import axios from "axios";

class ContactMe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location: config.location,
            devEmail: config.devEmail,
            name: '',
            email: '',
            subject: '',
            msg: '',
            sendingData: false
        };
    }

    render() { 
        return (
            (this.state.sendingData) ? <Loader /> :
            <div>
                <section className="colorlib-contact" data-section="contact" id="contact">
                    <div className="colorlib-narrow-content">
                        <div className="row">
                            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                                <span className="heading-meta">Contact</span>
                                <h2 className="colorlib-heading">Get in Touch</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-5">
                                <div className="colorlib-feature colorlib-feature-sm animate-box" data-animate-effect="fadeInLeft">
                                    <div className="colorlib-icon">
                                        <i className="icon-mail"></i>
                                    </div>
                                    <div className="colorlib-text">
                                        <p><a href={`mailto:${this.state.devEmail}`}>{this.state.devEmail}</a></p>
                                    </div>
                                </div>

                                <div className="colorlib-feature colorlib-feature-sm animate-box" data-animate-effect="fadeInLeft">
                                    <div className="colorlib-icon">
                                        <i className="icon-map"></i>
                                    </div>
                                    <div className="colorlib-text">
                                        <p>{this.state.location}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7 col-md-push-1">
                                <div className="row">
                                    <div className="col-md-10 col-md-offset-1 col-md-pull-1 animate-box" data-animate-effect="fadeInRight">
                                        <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
                                            <div className="form-group">
                                                <input type="text" id="name" value={this.state.name} onChange={this.onNameChange.bind(this)} className="form-control" placeholder="Name" />
                                            </div>
                                            <div className="form-group">
                                                <input type="email" className="form-control" placeholder="Email" id="email" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
                                            </div>
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Subject" id="subject" value={this.state.subject} onChange={this.onSubjectChange.bind(this)} />
                                            </div>
                                            <div className="form-group">
                                                <textarea name="msg" id="message" cols="30" rows="7" className="form-control" placeholder="Message" value={this.state.msg} onChange={this.onMessageChange.bind(this)}></textarea>
                                            </div>
                                            <div className="form-group">
                                                <input type="submit" className="btn btn-primary btn-send-message" value="Send Message" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }

    onNameChange(event) {
        this.setState({ name: event.target.value })
    }

    onEmailChange(event) {
        this.setState({ email: event.target.value })
    }

    onMessageChange(event) {
        this.setState({ msg: event.target.value })
    }

    onSubjectChange(event) {
        this.setState({ subject: event.target.value })
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({ sendingData: true });

        const data = {
            name: this.state.name,
            email: this.state.email,
            subject: this.state.subject,
            msg: this.state.msg
		}

		if (this.validateForm()) {
			axios({
				method: "POST",
				baseURL: 'https://api.harshtuwar.ml/',
				url: "/send",
				data: data
			}).then((response) => {
				if (response.status === 200) {
					alert("Message Sent.");
					this.resetForm()
				} else {
					alert("Unable to send.")
                }

                this.setState({ sendingData: false });
                window.location.reload();
			});
		}
	}
	
	validateForm() {
		if (this.state.name === '') {
			alert("Name field can not be empty!");
			return false;
		} else if (this.state.email === '') {
			alert("Email field can not be empty!");
			return false;
		} else if (this.state.msg === '') {
			alert("Content can not be empty!");
			return false;
		} else if (this.state.subject === '') {
			alert("Subject field can not be empty!");
			return false;
		} else {
			if (this.state.name !== '' && this.state.subject !== '' && this.state.email !== '' && this.state.msg !== ''){
				return true;
			} else {
				return false;
			}
		}
	}

    resetForm() {
        this.setState({ name: '', email: '', subject: '', msg: '' })
    }
}
 
const Loader = () => {
    return (
       <div className="loader"></div>
    );
}

export default ContactMe;