import React, { Component } from 'react';
import config from '../configuration.json';

// const SkillsContainer = styled.ul`
//   display: grid;
//   grid-template-columns: repeat(2, minmax(140px, 200px));
//   overflow: hidden;
//   padding: 0;
//   margin: 20px 0 0 0;
//   list-style: none;
// `;

export default class Experience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      experiences: config.experiences
    };
  }

  render() {
    return (
      <div>
        <section className="colorlib-experience" data-section="timeline">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                <span className="heading-meta">Experience</span>
                <h2 className="colorlib-heading animate-box">Let Experience be your Guide.</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="timeline-centered">
                  {this.state.experiences.map(exp => {
                    return <article className="timeline-entry animate-box" key={exp.key} data-animate-effect="fadeInLeft">
                              <div className="timeline-entry-inner">
                                <div className={`timeline-icon color-${exp.key}`}>
                                  <i className="icon-briefcase" />
                                </div>
                                <div className="timeline-label">
                                    <h2>{exp.position} at {exp.companyName}</h2>
                                    <span>{exp.startDate} - {exp.endDate}</span> <br />< br />
                                    <p className="introductoryLine">{exp.introductoryLine}</p>
                                    <h6>{exp.head1}</h6>
                                    <ul className="descPoints">
                                      {exp.head1Body.map(point => {
                                        return <li key={point.key}>{point.value}</li>
                                      })}
                                    </ul>
                                    <h6>{exp.head2}</h6>
                                    <p>{exp.skillsUsed}</p>
                                </div>
                              </div>
                            </article>
                  })}
                  <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-none">
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
