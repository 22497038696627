import React, { Component } from 'react';
import config from '../configuration.json';

export default class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aboutMeSection1: config.aboutMeSection1,
            aboutMeSection2: config.aboutMeSection2,
            aboutMeSectionSkills: config.aboutMeSectionSkills
        };
    }
    render() {
        return (
            <div>
                <section className="colorlib-about" data-section="about" id="about">
                    <div className="colorlib-narrow-content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                                    <div className="col-md-12">
                                        <div className="about-desc">
                                            <span className="heading-meta">About Him</span>
                                            <h2 className="colorlib-heading">This is who He is.</h2>
                                            <p>{this.state.aboutMeSection1}</p>
                                            <ul>
                                                {this.state.aboutMeSectionSkills.map(title => {
                                                    return <div className="panel panel-default" key={title.key}>
                                                                <div className="panel-heading" role="tab" id={`heading_${title.key}`}>
                                                                    <h4 className="panel-title">
                                                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href={`#collaps_${title.key}`} aria-expanded="false" aria-controls={`collaps_${title.key}`}>{title.label}</a>
                                                                    </h4>
                                                                </div>
                                                                <div id={`collaps_${title.key}`} className="panel-collapse collapse" role="tabpanel" aria-labelledby={`heading_${title.key}`}>
                                                                    <div className="panel-body">
                                                                        {title.skills.map(skill => {
                                                                            return <span key={skill.key} className="badge badge-info skillBadge">{skill.value}</span>
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                })}
                                            </ul><br />
                                            <p>{this.state.aboutMeSection2}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
