import React, { Component } from "react";
import Configs from '../configuration.json';
import axios from "axios";
import ProjectCard from "./projectCards"


class Project extends Component {
	constructor(props) {
		super(props);
		this.state = {
			heading: "Projects",
			projectsArray: []
		};
	}
	componentDidMount = () => {
		this.handleRequest();
	};

	handleRequest = e => {
		const allowedProjects = [
			231708269, 231845007, 183703204, 180878120, 179369767, 177886802, 167870965,
			163630531, 135954430, 163449526, 165301606, 164272004, 161082760, 135499205,
			134996857, 250672542, 254782224, 255152208, 273995080, 285967757, 286314536
		];
		
		axios
			.get(Configs.gitHubLink + Configs.gitHubUsername + Configs.gitHubQuerry)
			.then(response => {
				// handle success
				const projects = []
				// show specific projects
				// eslint-disable-next-line
				response.data.map(repo => { if (allowedProjects.includes(repo.id)) { projects.push(repo); } })

				this.setState({
					projectsArray: projects
				});
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			})
			.finally(function () {
				// always executed
			});
	};

	render() {
		return (
			<div>
				<section className="colorlib-about" data-section="projects" id="projects">
					<div className="colorlib-narrow-content">
						<div className="row">
							<div className="col-md-12">
								<div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
									<div className="col-md-12">
										<div className="about-desc">
											<span className="heading-meta">Projects</span>
											<h2 className="colorlib-heading">Batman would be jealous too.</h2>
											<div className="row">
												{this.state.projectsArray.map(project => (
													<ProjectCard
														key={project.id}
														id={project.id}
														value={project}
													></ProjectCard>
												))}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default Project;
