import React, { Component } from 'react';
import './App.css';
import Sidebar from './components/sidebar'
import Introduction from './components/introduction'
import About from './components/about'
import Experience from './components/experience'
import Project from './components/project'
import Education from './components/education';
import ContactMe from './components/contact';

class App extends Component {
  render() {
    return (
      <div id="colorlib-page">
        <div id="container-wrap">
         	<Sidebar />
			<div id="colorlib-main">
				<Introduction />
				<hr className="m-0"></hr>
				<About />
				<hr className="m-0"></hr>
				<Experience />
				<hr className="m-0"></hr>
				<Education />
				<hr className="m-0"></hr>
				<Project />
				<hr className="m-0"></hr>
				<ContactMe />
			</div>
      	</div>
      </div>
    );
  }
}

export default App;
