import React, { Component } from 'react';
import config from '../configuration.json';

export default class Education extends Component {
	constructor(props) {
		super(props);
		this.state = {
			education: config.education
		};
	}

	render() {
		return (
			<div>
				<section className="colorlib-experience" data-section="education">
					<div className="colorlib-narrow-content">
						<div className="row">
							<div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
								<span className="heading-meta">Education</span>
								<h2 className="colorlib-heading animate-box">Knowledge Is Power.</h2>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="timeline-centered">
									{this.state.education.map(clg => {
										return <article className="timeline-entry animate-box" key={clg.key} data-animate-effect="fadeInLeft">
											<div className="timeline-entry-inner">
												<div className={`timeline-icon iconUnique color-${clg.key}`}>
													<i className="icon-study" />
												</div>
												<div className="timeline-label">
													<h2>{clg.program}</h2>
													<h2>{clg.insituteName}</h2>
													<span>{clg.startDate} - {clg.endDate}</span> <br />< br />
													{/* <p>{clg.desc}</p> */}
												</div>
											</div>
										</article>
									})}
									<article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
										<div className="timeline-entry-inner">
											<div className="timeline-icon color-none">
											</div>
										</div>
									</article>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	}
}
